import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import index from '@angular/cli/lib/cli';

interface Datas {
  name: string;
  index: number;
}

@Component({
  selector: 'app-dialog-menu',
  templateUrl: './dialog-menu.component.html',
  styleUrls: ['./dialog-menu.component.css']
})
export class DialogMenuComponent implements OnInit {

  datas: Datas;

  constructor(
    public dialogRef: MatDialogRef<DialogMenuComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log(this.data);
  }

  promo() {
    this.dialogRef.close({ name: 'Promo', index: this.data.index });
  }

  supply() {
    this.dialogRef.close({ name: 'Supply', index: this.data.index });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
