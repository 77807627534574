import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule} from '@angular/forms';
import { MatButtonModule} from '@angular/material/button';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule} from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule} from '@angular/material';

import { AppComponent } from './app.component';
import { EntityComponent } from './entity/entity.component';
import { ListComponent } from './list/list.component';

import { AppRoutingModule } from './app-roting.module';
import { NavbarAppComponent } from './navbar-app/navbar-app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';

import { AuthenticationService } from './_services/authentication.service';
import { AlertService } from './_services/alert.service';
import { UserService } from './_services/user.service';
import { AuthGuard } from './_guards/auth.component';
import { SidenavService } from './_services/sidenav.service';
import { Shared } from './_services/nav.service';
import { ProjectService } from './_services/project.service';
import { RetailerService } from './_services/retailer.service';

import { HttpModule} from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { AddrComponent } from './addr/addr.component';

import 'hammerjs';
import { DialogEntitysComponent } from './_dialogs/dialog-entitys/dialog-entitys.component';
import { DialogSupplyComponent } from './_dialogs/dialog-supply/dialog-supply.component';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './auth/token.inteceptor';
import { ErrorInterceptor} from './auth/error.inteceptor';

import {AlertComponent} from './_directives/alert.component';
import { NgxGalleryModule} from 'ngx-gallery';


import localeRu from '@angular/common/locales/ru';
import { registerLocaleData } from '@angular/common';
import { DialogMenuComponent } from './_dialogs/dialog-menu/dialog-menu.component';
import { DialogPromoComponent } from './_dialogs/dialog-promo/dialog-promo.component';


registerLocaleData(localeRu);

export class MyHammerConfig extends HammerGestureConfig {
    overrides = <any> {
        'pinch': { enable: false },
        'rotate': { enable: false },
        'swipe': {enable: false},
    };
}

@NgModule({
  entryComponents: [
    DialogEntitysComponent,
    DialogSupplyComponent,
    DialogPromoComponent,
    DialogMenuComponent
  ],
  declarations: [
    AlertComponent,
    AppComponent,
    EntityComponent,
    ListComponent,
    NavbarAppComponent,
    LoginComponent,
    RegisterComponent,
    AddrComponent,
    DialogEntitysComponent,
    DialogSupplyComponent,
    DialogPromoComponent,
    DialogMenuComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,

    //Material Design
    MatButtonModule,
    MatCheckboxModule,
    MatListModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    MatSidenavModule,
    MatMomentDateModule,
    MatDatepickerModule,
    MatSelectModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    HttpModule,
    HttpClientModule,
    NgxGalleryModule,
  ],
  providers: [
    AuthGuard,
    AlertService,
    AuthenticationService,
    UserService,
    SidenavService,
    Shared,
    ProjectService,
    RetailerService,
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
