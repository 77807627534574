import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';

import { environment } from '../../environments/environment';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { AlertService } from './alert.service';



@Injectable()
export class RetailerService {

  constructor(
    private http: Http,
    private alertService: AlertService,
    private httpClient: HttpClient,
  ) { }


   getRetailerForProject(projectid: number) {
    const url = environment.apiPath + 'v1/retailer/getretailer?projectid=' + projectid;
    console.log(url);
    return this.httpClient.get<any>(url);
  }

  getReportsRetailersDayCounts(projectid: number, timestamp: Date) {
    const url = environment.apiPath + 'v1/report/getretailerscount?projectid=' + projectid + '&timestamp=' + timestamp;
    console.log(url);
    return this.httpClient.get<any>(url);
  }

  getRetailersAddressForProject(projectid, retailerid: number) {
    const url = environment.apiPath + 'v1/retailer/getretaileraddress?projectid=' + projectid + '&retailerid=' + retailerid;
    console.log(url);
    return this.httpClient.get<any>(url);
  }

  getReportStateData(projectid, retailerid: number, timestamp: Date) {
    const url = environment.apiPath + 'v1/report/getreporttable?projectid=' + projectid + '&retailerid=' + retailerid + '&timestamp=' + timestamp;
    console.log(url);
    return this.httpClient.get<any>(url);
  }

  getReportsCount(retailerid: number, projectid: number, timestamp: Date) {
    const url = environment.apiPath + 'v1/report/getcount?retailerid=' + retailerid + '&projectid=' + projectid + '&timestamp=' + timestamp;
    console.log(url);
    return this.httpClient.get<any>(url);
  }

  getReportDetail(reportid: number) {
    const url = environment.apiPath + 'v1/report/' + reportid;
    console.log(url);
    return this.httpClient.get<any>(url);
  }

  getAddressReportsCount(addressid: number, projectid: number, timestamp: string) {
    const url = environment.apiPath + 'v1/report/getaddresscount?addressid=' + addressid + '&projectid=' + projectid + '&timestamp=' + timestamp;
    console.log(url);
    return this.httpClient.get<any>(url);
  }
}




