import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';

import { environment } from '../../environments/environment';
import {HttpClient, HttpErrorResponse, HttpParams, HttpRequest} from '@angular/common/http';
import {HttpEvent, HttpEventType, HttpResponse} from '@angular/common/http';
import {AlertService} from './alert.service';

@Injectable()
export class ProjectService {

  constructor(
    private http: Http,
    private httpClient: HttpClient,
    private alertService: AlertService,
  ) { }

  getProjects(userid: string){
    const url = environment.apiPath + 'v1/project/?userid=' + userid;
    return this.httpClient.get<any>(url);
  }

  getProject(projectid: number) {
    const url = environment.apiPath + 'v1/project/project/?projectid=' + projectid;
    return this.httpClient.get<any>(url);
  }

  getProjectName(projectid: number) {
     const url = environment.apiPath + 'v1/project/name/?projectid=' + projectid;
     return this.httpClient.get<any>(url);
   }

  getAddr(projectid: number) {
     const currentUser = JSON.parse(localStorage.getItem('currentUser'));
     const userid = currentUser.result.user_id;
     console.log(userid);

     const url = environment.apiPath + 'v1/project/listaddr/?projectid=' + projectid + '&userid=' + userid;
     return this.httpClient.get<any>(url);
  }

  getEntity(projectid: number, retailerid: number, addressid: number) {
     const url = environment.apiPath + 'v1/project/entity/?projectid=' + projectid + '&retailerid=' + retailerid + '&addressid=' + addressid;
     return this.httpClient.get<any>(url);
  }

  getEntitySku(projectId: number, retailerId: number, entityId: number) { // todo: добавь для штрихкода
    console.log('Отправь на бек projectId = ', projectId, ' retailerId = ', retailerId, ' entityId = ', entityId)
    const url = environment.apiPath + `v1/project/entity_sku?projectid=${projectId}&retailerid=${retailerId}&entityid=${entityId}`
    return this.httpClient.get<any>(url);
 }



  getPromoThatReported(projectid: number, retailerid: number, addressid: number) {
    const url = environment.apiPath + 'v1/promo/hasreports/?projectid=' + projectid + '&retailerid=' + retailerid + '&addressid=' + addressid;
    return this.httpClient.get<any>(url);
  }

  fileupload(formData: FormData) {
     const options = {
       params: new HttpParams(),
       reportProgress: true,
       observe: 'events'
     };


     console.log(FormData);
     const url = environment.apiPath + 'v1/global/fileupload';

     //const req = new HttpRequest('POST', url, formData, {
     //  reportProgress: true,
     //  params: new HttpParams(),
     //});

     return this.httpClient.post<any>(url, formData, {
         params: new HttpParams(),
         reportProgress: true,
         observe: 'events',
         });

    // The `HttpClient.request` API produces a raw event stream
    // which includes start (sent), progress, and response events.
    /*
    return this.httpClient.request(req).pipe(
      map(event => this.getEventMessage(event, formData)),
      tap(message => this.showProgress(message)),
      last(), // return last (completed) message to caller
      catchError(this.handleError(formData))
    );
    */


    //return this.httpClient.request(req)
   /*   .subscribe(event => {
      // Via this API, you get access to the raw event stream.
      // Look for upload progress events.
      if (event.type === HttpEventType.UploadProgress) {
        // This is an upload progress event. Compute and show the % done:
        const percentDone = Math.round(100 * event.loaded / event.total);
        console.log(`File is ${percentDone}% uploaded.`);
      } else if (event instanceof HttpResponse) {
        console.log('File is completely uploaded!');
      }
    });
    */
  }

/*
  fileupload(formData: FormData) {
    const url = environment.apiPath + "v1/global/fileupload";
    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true,
    });
    return this.httpClient.request(req);

  }
*/

  /** Return distinct message for sent, upload progress, & response events */
  private getEventMessage(event: HttpEvent<any>, file: File) {
    switch (event.type) {
      case HttpEventType.Sent:
        return `Uploading file "${file.name}" of size ${file.size}.`;

      case HttpEventType.UploadProgress:
        // Compute and show the % done:
        const percentDone = Math.round(100 * event.loaded / event.total);
        return `File "${file.name}" is ${percentDone}% uploaded.`;

      case HttpEventType.Response:
        return `File "${file.name}" was completely uploaded!`;

      default:
        return `File "${file.name}" surprising upload event: ${event.type}.`;
    }
  }

}
