import { Component, OnInit } from '@angular/core';
import { Shared } from '../_services/nav.service';
import { ProjectService} from '../_services/project.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {


  constructor(
    private projectService: ProjectService,
    private sharedResource: Shared
  ) { }

  lists: Array<any>;

  ngOnInit() {

    this.sharedResource.navName.next('Проекты');

    const key = JSON.parse(localStorage.getItem('currentUser'));
    this.projectService.getProjects(key.result.user_id).subscribe(
      data => {
	console.log(data);
        this.lists = data.result;
      },
      error => {
        console.log('Error');
      }
    );


   }
}
