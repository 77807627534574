import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListComponent} from './list/list.component';
import { EntityComponent } from './entity/entity.component';
import { LoginComponent} from './login/login.component';
import { RegisterComponent} from './register/register.component';
import { AddrComponent} from './addr/addr.component';
import { AuthGuard } from './_guards/auth.component';

const routes: Routes = [

  { path : '' , component: ListComponent , canActivate: [AuthGuard]},
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'list', component: ListComponent, canActivate: [AuthGuard]},
  { path: 'addr/:id', component: AddrComponent, canActivate: [AuthGuard]},
  { path: 'entity/:projectid/:addrid/:retailerid', component: EntityComponent, canActivate: [AuthGuard] },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
