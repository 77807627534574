export class User {
  id: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
}

export class Project {
  Idproject: number;
  Name: string;

  Reportremains: boolean;
  Reportvirtualremains: boolean;
  Reportqtyexpprod: boolean;
  Reportdelayreason: boolean;
  Reportpriceonshelf: boolean;
  Reportqtyexpdate: boolean;
  Actionreportremains: boolean;
  Actionreportpriceonshelf: boolean;
  Actionreportpricetagsel: boolean;
  Actionreportextendedlayout: boolean;
  Actionreportdmp: boolean;
}

export class Retailer {
  id: number;
  name: string;
}

export class ReportsCounts {
  points: number;
  reports: number;
}

export class RetailerReportData {
  retailer: Retailer;
  reportcount: ReportsCounts;
}

export class Address {
  AddressId: number;
  RetailerId: number;
  RetailerName: string;
  ProjectId: number;
  City: string;
  Street: string;
  House: string;
}

export class ReportData {
  ReportId:	number;
  Timestamp:  Date;
  RetailerId: number;
  RetailerName:  string;
  RetailerAddressId: number;
  RetailerAddressName:	string;
  Username:	string;
}

//формирование на entity list
export class ReportEntity {
  Projectid: number;
  Retailerid: number;
  Addressid: number;
  Data: Array<Entity>;
  Pics: Array<Pics>;
  Comment: string;
}

export class Pics {
  Id: string;
  Original: string;
  Medium: string;
  Trumbnail: string;
}

export class ReportTable {
  Address: Address;
  Report: ReportData;
  Merc: User;
}

export class ReportDetail {
  NstProjectId: number;
  NstFieldCode: number;
  ReportId: number;
  Timestamp: Date;
  ProjectId: number;
  ProjectName: string;
  RetailerId: number;
  RetailerName: string;
  RetailerAddressId: number;
  RetailerAddressName: string;
  EntityId: number;
  EntityName: string;
  EntityRetailerSku: string;
  Presence: string;
  Quantity: number;
  UserId: number;
  Username: string;
}

export class Report {
  Report: Array<ReportDetail>;
  Files: Array<string>;
}

export class Entity {
  EntityId: number;
  EntityName: string;
  Value: string;
  Qty: number;
  LastSupplyDate: number;
  Class: string;
  SelectIndex: number;
  PromoReport: PromoReport;

  Remains: number;
  Virtualremains: number;
  Qtyexpprod: number;
  Delayreason: string;
  Priceonshelf: number;
  Qtyexpdate: number;
}

export enum Values {
  OOS = 0,
  V,
  OUT
}

export class CheckData {
   Value: string;
   Class: string;
}

export class SelectedValue {
  Id: number;
  Value: any;
}

export class ApiResponse {
  api_id: number;
  result: Array<any>;
}

export class PromoReport {
  presence: string;
  price: string;
  extraPlace: string;
  comment: string;
  remains: string;
  priceonshelf: string;
  pricetagsel: string;
  extendedlayout: string;
  dmp: string;
}