import {Observable, throwError} from 'rxjs/index';
import {catchError} from 'rxjs/operators';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';
import {AuthenticationService} from '../_services/authentication.service';
import {Injectable} from '@angular/core';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    public auth: AuthenticationService,
    private router: Router
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            //this.auth.removeTokens();
            this.router.navigate(['/login']);
            location.reload(true);
          }
          else {
              return throwError(error);
          }
        }));
  }
}
