import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { AlertService} from '../_services/alert.service';
import { AuthenticationService} from '../_services/authentication.service';
import {FormControl, Validators} from '@angular/forms';
import {Shared} from '../_services/nav.service';

@Component({
  moduleId: module.id,
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  model: any = {};
  loading = false;
  returnUrl: string;

  username = new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z]+$')]);
  password = new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z]+$')]);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private sharedResource: Shared
  ) { }

  ngOnInit() {
    this.sharedResource.enableNavbar.next(false);
    this.authenticationService.logout();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  getUsernameErrorMessage() {
    return this.username.hasError('required') ? 'Введите имя пользователя' :
      this.username.hasError('username') ? 'Not a valid username' :
        '';
  }

  getPasswordErrorMessage() {
    return this.password.hasError('required') ? 'Введите пароль' :
      this.password.hasError('password') ? 'Not a valid password' :
        '';
  }

  login() {
    this.loading = true;
    this.authenticationService.login(this.model.username, this.model.password)
      .subscribe(
        data => {
          this.router.navigate([this.returnUrl]);

        },
      error => {
          this.alertService.error('User Not found or Username/password mismatch');
          this.loading = false;
      });
  }
}
