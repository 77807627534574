import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';

import { User} from '../_models/user';
import { HttpClient, HttpErrorResponse} from '@angular/common/http';
import { environment } from '../../environments/environment';
import {AlertService} from './alert.service';



@Injectable()
export class UserService {
  constructor(
 	private http: Http,
	private httpClient: HttpClient,
	private alertService: AlertService
  ) { }

  getAll() {
    const url = environment.apiPath + 'v1/user';
    return this.httpClient.get<any>(url);
  }

  create(user: User) {
    //return this.httpClient.post('/api/users', user, this.jwt()).map((response: Response) => response.json());
    const url = '/api/users';
    return this.httpClient.post(url, user);
  }

  /*
  getById(id: number) {
    return this.http.get('/api/users/' + id, this.jwt()).map((response: Response) => response.json());
  }

  update(user: User) {
    return this.http.put('/api/users/' + user.id, user, this.jwt()).map((response: Response) => response.json());
  }

  delete(id: number) {
    return this.http.delete('/api/users/' + id, this.jwt()).map((response: Response) => response.json());
  }
  */
  // private helper methods

  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.result.access_token) {
      const headers = new Headers({ 'Authorization': 'Bearer ' + currentUser.result.access_token });
      return new RequestOptions({ headers: headers });
    }
  }



}
