import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit, EventEmitter} from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../_services/project.service';
import { AlertService } from '../_services/alert.service';
import { HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { Shared } from '../_services/nav.service';
import { MatSnackBar } from '@angular/material';
import { MatDialog } from '@angular/material';
import { Entity, Pics} from '../_models/user';
import { Values } from '../_models/user';
import { Project } from '../_models/user';
import { ReportEntity } from '../_models/user';
import { DialogEntitysComponent } from '../_dialogs/dialog-entitys/dialog-entitys.component';
import { DialogSupplyComponent } from '../_dialogs/dialog-supply/dialog-supply.component';
import { DialogMenuComponent } from '../_dialogs/dialog-menu/dialog-menu.component';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { observable, Observable, Subject} from 'rxjs/index';
import { NgxGalleryOptions, NgxGalleryImage } from 'ngx-gallery';
import { HttpEvent, HttpEventType} from '@angular/common/http';
import { Subscription} from 'rxjs/index';
import { DialogPromoComponent } from '../_dialogs/dialog-promo/dialog-promo.component';

// Main component
@Component({
  selector: 'app-entity',
  templateUrl: './entity.component.html',
  styleUrls: ['./entity.component.css']
})
export class EntityComponent implements OnInit {
  fileloaderClass = 'hide';
  floatingMenuClass = 'hide';
  floatingMenuOpenClass = 'flowing-menu scale-in-bl';
  floatingMenuCloseClass = 'flowing-menu scale-out-bl';

  acolor = 'primary';
  amode = 'determinate';
  avalue = 5;

  uploadFileCount = 0;
  uploadedFileCount = 0;

  sub: Subscription;

  projectid: number;
  project: Project;
  addrid: number;
  userid: number;
  retailerid: number;
  reportid: number;
  error = '';
  lists: Array<Entity>;
  skuList: Array<any> // todo: добавь штрихКод
  promos: Array<number>;
  url: any;
  files: Array<any> = [];

  sendObj = <ReportEntity> {
    Pics: [],
  };

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  // check if loading files
  loading: boolean;

  // emit when files resized
  fUploaded: EventEmitter<boolean>;
  fileUploaded: EventEmitter<boolean>;

  dirEnum = Values;

  CheckValMap = {
    'OOS': { Class: 'fa fa-square-o fa-2x' },
    'V': { Class: 'fa fa-check-square-o fa-2x' },
    'OUT': { Class: 'fa fa-minus-square-o fa-2x'}
  };

  indexList: number;

  supplyes = [
    { id: 0, name: 'Никогда', value: -2},
    { id: 1, name: 'Нет в матрице ТТ', value: -1 },
    { id: 2, name: '1 месяц назад', value: moment().subtract(1, 'months').unix()},
    { id: 3, name: '3 месяца назад', value: moment().subtract(3, 'months').unix()},
    { id: 4, name: '6 месяцев назад', value: moment().subtract(6, 'months').unix()}
    ];


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private alertService: AlertService,
    private httpClient: HttpClient,
    private sharedResource: Shared,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
    ) {
        this.fUploaded = new EventEmitter<boolean>();
        this.fileUploaded = new EventEmitter<boolean>();
        this.loading = false;
    }

  ngOnInit() {

    this.skuList = [] // todo: добавь штрихкод

    this.galleryOptions = [
      // { "image": false, "imageSwipe": true, "thumbnailsSwipe": true, "previewSwipe": true },
      { 'image': false, 'width': '100%', 'thumbnailsMargin': 0, 'thumbnailsPercent': 100 },
      { 'breakpoint': 3000, 'height': '100px', 'width': '100%', 'thumbnailsColumns': 10, 'thumbnailsMoveSize': 10 },
      { 'breakpoint': 700, 'height': '70px', 'width': '100%', 'thumbnailsColumns': 4, 'thumbnailsMoveSize': 4  },
    ];

    this.galleryImages = [];

    this.sharedResource.navName.next('');
    this.projectid = Number(this.route.snapshot.params['projectid']);
    this.addrid = Number(this.route.snapshot.params['addrid']);
    this.retailerid = Number(this.route.snapshot.params['retailerid']);

    const res = JSON.parse(localStorage.getItem('currentUser'));
    this.userid = Number(res.result.user_id);

    this.projectService.getProject(this.projectid).subscribe((data) => {
      console.log(data)
      this.project = data.result

      this.projectService.getEntity(this.projectid, this.retailerid, this.addrid).subscribe(
        (data) => {
  
          for (let dataItem of data.result) { // todo: добавь штрихкод
              console.log('!!!!!!!!!!!!!.берем ску для объекта = ', dataItem.EntityId)
              this.projectService.getEntitySku(this.projectid, this.retailerid, dataItem.EntityId).subscribe( // todo: добавь штрихкод
                (sku) => {
                  console.log('test sku = ', sku.result)
                  this.skuList.push(sku.result)
                },
                (err) => {
                  console.log(err)
                }
      
              )
  
          }  
  
          this.projectService.getPromoThatReported(this.projectid, this.retailerid, this.addrid).subscribe(
            (promosRep) => {
              console.log(promosRep)
              this.promos = promosRep.result;
  
              this.lists = data.result;
              this.lists.forEach( (list, index) => {
                if (!list.Value) {
                  list.Value = this.dirEnum[0];
                  list.Class = this.CheckValMap[list.Value].Class;
                }
              });
  
            },
            (err) => {
              console.log(err);
            });
         },
        (err) => {
          console.log(err);
         }
      );
    },
    (err) => {
      console.log(err);
    })

    // подписка на загрузку файлов
    this.fUploaded.subscribe((data) => {
      if (data === true) {
        // this.loader = "hide";
        this.snackBar.open('Отчет загружен', '' , { duration: 5000} );
        setTimeout( () => {
          this.loading = false;
          this.router.navigate(['/']);
        }, 3000 );
      }

      if (data === false) {
        // this.loader = "hide";
            // this.snackBar.open("Ошибка загрузки!!!", '' , { duration: 5000} );
            setTimeout( () => {
              this.loading = false;
              // this.router.navigate(['/']);
            }, 3000 );
      }

    });
  }

  ngAfterViewInit() { }

  isAllPromosFilled(): boolean {
    for (let i = 0; i < this.lists.length; i++) {
      console.log(this.lists[i]);
      if ( this.lists[i]['Promo'].Id > 0 && this.promos && this.promos.includes(this.lists[i].EntityId) ) {
        continue
      }

      if (this.lists[i]['Promo'].Id > 0 && !this.lists[i].PromoReport.presence ) {
        this.alertService.error('Не могу сохранить. Не все промо отчеты заполнены!');
        return false;
      }
    }
    return true;
  }

  openDialogMenu(index, list): void {
    const dialogRef = this.dialog.open(DialogMenuComponent, {
      maxWidth: '500px',
      width: '95%',
      data: { index: index, list: list }
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result) {
          switch (result.name) {
            case 'Supply':
              this.openDialogSupply(result.index);
              break;
            case 'Promo':
              this.openDialogPromo(result.index, list);
              break;
            default:
              break;
          }
        }
    });
  }

  openDialogSupply(index): void {
    this.indexList = index;
    const dialogRef = this.dialog.open(DialogSupplyComponent, {
      maxWidth: '500px',
      width: '95%',
      data: { lastSup: this.lists[this.indexList].LastSupplyDate , index: this.lists[this.indexList].SelectIndex}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (this.supplyes[result]) {
        this.lists[this.indexList].LastSupplyDate = this.supplyes[result].value;
        // Ставим картинку для OUT и выставляем тип OUT
        this.lists[this.indexList].Class = this.CheckValMap.OUT.Class;
        this.lists[index].Value = 'OUT';
      }
      this.lists[this.indexList].SelectIndex = result;
     });
  }

  openDialogPromo(index, list): void {
    const item = {
      list: list,
      project: this.project
    }
    const dialogRef = this.dialog.open(DialogPromoComponent, {
      maxWidth: '500px',
      width: '95%',
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.lists[index].PromoReport = result;
      //  this.lists[index].Class = this.CheckValMap.V.Class;
      //  this.lists[index].Value = 'V';
      }
    });
  }

  openDialog(index): void {

    this.indexList = index;
    const item = this.lists[this.indexList];

    const baseReportItem = {
      Reportremains: this.project.Reportremains,
      Reportvirtualremains: this.project.Reportvirtualremains,
      Reportqtyexpprod: this.project.Reportqtyexpprod,
      Reportdelayreason: this.project.Reportdelayreason,
      Reportpriceonshelf: this.project.Reportpriceonshelf,
      Reportqtyexpdate: this.project.Reportqtyexpdate,
      Qty:'',
      Remains: '',
      Virtualremains: '',
      Qtyexpprod: '',
      Delayreason: '',
      Priceonshelf: '',
      Qtyexpdate: ''
    };

    const dialogRef = this.dialog.open(DialogEntitysComponent, {
      width: '300px',
      data: baseReportItem
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)

      // Если  сбрасываем  диалог, то прилетает result=undefined. В этом случае выставляем Qty в 0
      if (!result) {
        this.lists[this.indexList].Qty = 0;
        this.lists[this.indexList].Remains = undefined;
        this.lists[this.indexList].Virtualremains = undefined;
        this.lists[this.indexList].Qtyexpprod = undefined;
        this.lists[this.indexList].Delayreason = undefined;
        this.lists[this.indexList].Priceonshelf = undefined;
        this.lists[this.indexList].Qtyexpdate = undefined;
      } else {
        this.lists[this.indexList].Qty = result.Qty;
        this.lists[this.indexList].Remains = result.Remains;
        this.lists[this.indexList].Virtualremains = result.Virtualremains;
        this.lists[this.indexList].Qtyexpprod = result.Qtyexpprod;
        this.lists[this.indexList].Delayreason = result.Delayreason;
        this.lists[this.indexList].Priceonshelf = result.Priceonshelf;
        this.lists[this.indexList].Qtyexpdate = result.Qtyexpdate;
      }

      this.countQtyLength(this.indexList);
    });
  }

  findSku(entityId) { // todo: сделай штрихкод
    console.log('найди sku , где entityId = ', entityId)
    let sku = this.skuList.find(skuListItem => skuListItem.EntityId === entityId)
    console.log('результат sku = ', sku)
    
    return sku.Sku
  }



  // genReportid(): number {
  //  let reportid = Math.round( new Date().getTime()/100 );
  //  reportid = reportid
  //  return reportid
  // }

  guid(): string {
    function uuid() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return uuid() + uuid();
  }

  // Смотрим введено ли кол-во. Если да, то меняем значение в V + class, если нет, то значение OSS + class
  countQtyLength(index) {
    setTimeout( () => {
      console.log(this.lists[index].Qty);
      if (this.lists[index].Qty > 0) {
        this.lists[index].Value = this.dirEnum[1];
        this.lists[index].Class = this.CheckValMap[this.lists[index].Value].Class;
      } else {
        // Включаем OSS
        this.lists[index].Value = this.dirEnum[0];
        this.lists[index].Class = this.CheckValMap[this.lists[index].Value].Class;
      }
    }, 5);
  }

/*
  //Перебираем возможные варианты по нажатию на box рядом с позицией списка товара
  //при вызове возвращаем следующее значение из enum и меняем имя класса.
  changeBox(index) {
    this.lists[index].Value = this.cicleEnum(this.lists[index].Value);
    this.lists[index].Class = this.CheckValMap[this.lists[index].Value].Class

    //открываем ввод кол-ва, если переключили в V
    if (this.lists[index].Value == "V") {
      this.openDialog(index);
    }
    //Изменяем кол-во на ноль, если переключили в OOS, сбрасываем lastsupply
    if (this.lists[index].Value == "OOS") {
      console.log(this.lists[index].Qty);
      this.lists[index].Qty = 0;
      this.lists[index].LastSupplyDate = 0;
    }
  }
*/

  // при вызове возвращаем следующее значение из enum и меняем имя класса.
  changeBox(index) {
    this.lists[index].Value = this.cicleEnum(this.lists[index].Value);
    this.lists[index].Class = this.CheckValMap[this.lists[index].Value].Class;
  }


  // итератор для enum по имени (Берем из модели Values)
  cicleEnum(name: string): string {
    const id = this.dirEnum[name];
    if (this.dirEnum[id + 1]) {
       return this.dirEnum[id + 1];
    }
    return this.dirEnum[0];
  }

  // Отправка формы отчета и файлов
  save() {
    this.loading = true;
    // this.loader = "spinner_show";
    // let reportid = this.genReportid().toString();
    const key = JSON.parse(localStorage.getItem('currentUser'));
    const params = new HttpParams();
    const options = {
      // headers: new HttpHeaders().set('Authorization', this.loopBackAuth.accessTokenId),
      params: params,
      reportProgress: true
      // withCredentials: true,
    };

    const c = Array<any>();

    // sendObj.Pics содержит картинки. Добаввляются в addPics
    this.sendObj.Projectid = this.projectid;
    this.sendObj.Retailerid = this.retailerid;
    this.sendObj.Addressid = this.addrid;
    this.sendObj.Data = this.lists;

    const dataUrl = environment.apiPath + 'v1/report/' ;
    this.httpClient.post(dataUrl, JSON.stringify(this.sendObj), options)
      .subscribe(
        data => {
          this.fUploaded.emit(true);
        },
        (error: HttpErrorResponse)  => {
          console.log(error.message);
          this.alertService.error('Не удалось сохранить отчет. Error:' + error.message);
          this.fUploaded.emit(false);
        });
  }

  takePhoto() {
    console.log('Take photo!');
  }

// Формируем правильную структуру объекта для галереи
  formGaleryImage (data: any): NgxGalleryImage {
    const image1 = <NgxGalleryImage>{};

    image1.small = data.Thumbnail;
    image1.medium = data.Medium;
    image1.big = data.Medium;

    return image1;
  }

  // Загрузка фото
  addPics(event, files) {
    console.log('Add pics files');

    if (this.sub) {
      this.sub.unsubscribe();
    }

    let i = 0;
    if (event.target.files && event.target.files[0]) {
      this.uploadedFileCount = 0;
      this.loading = true;
      this.uploadFileCount = event.target.files.length;

      // перебираем добавленные файлы по одному. Делаем подписку через присваивание, иначе не выйдет отписаться.
      /* Вот уточнение
       .subscribe() returns a Subscription
        You should use this to unsubscribe

        e.g. Parent has a reloadSubject: Subject;

        child1 -> subscribes
        child2 -> subscribes
        child1 - "WORKS" -> unsubscribe's his subscription

        ngOnInit{
          sub: Subscription = parent.subscribe();
        }
        onDestroy{
          this.sub.unsubscribe();
        }

        child2 - "DOES NOT WORK" -> unsubscribe's the whole parent

        ngOnInit{
          parent.subscribe();
        }

        onDestroy{
          parent.unsubscribe();
        }
        If you call unsubscribe on the parent both children are gone.
        If you unsubscribe the Subscription that you get from the .subscribe() then only one child is unsubscribed.
      */
      this.sub = this.fileUploaded
          .subscribe((data) => {
            if (i === event.target.files.length) {
              console.log('ALL FILES UPLOADED!!!');
              this.loading = false;
              this.fileloaderClass = 'hide';

            } else {
              const fileName = this.guid();
              const formData = new FormData();
              const fullName = fileName + '.jpeg';
              formData.append('upload', event.target.files[i], fullName);

              this.fileupload(formData)
                .subscribe((data) => {
                    console.log('Data: ', data);
                  }
                );
              i = i + 1;
            }
          }
        );

       this.fileloaderClass = 'spinner_show';
       this.fileUploaded.emit(true);
    }
  }

  private fileupload (formData: FormData): Subject<any> {
   const subject = new Subject<boolean>();
   this.projectService.fileupload(formData)
    .subscribe(
    (eventr: any) => {
      console.log(event);
        switch (eventr.type) {
          case HttpEventType.Sent:
            // this.showSpinner = true;
            console.log('Request sent!');
          break;
          case HttpEventType.ResponseHeader:
            console.log('Response header received!');
          break;
          case HttpEventType.UploadProgress:
            const percentDone = Math.round(100 * eventr.loaded / eventr.total);
            console.log(`File is ${percentDone}% uploaded.`);
            this.avalue = percentDone;
            break;
          case HttpEventType.Response:
            if (eventr.type === 4) {
              console.log('😺 Done!', eventr);
              this.uploadedFileCount = this.uploadedFileCount + 1;
              this.sendObj.Pics.push(eventr.body.result);
              this.galleryImages.push(this.formGaleryImage(eventr.body.result));
              this.fileUploaded.emit(true);
              this.avalue = 0;
              return subject.next(true);

            }
        }
      },
      (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {

            this.alertService.error('Error upload file');
             this.loading = false;
          }
      });
   return subject;
}


  // Canvas generates images with 20 bytes header (before jpeg data segments start). You can slice head with exif segments from original file and replace first 20 bytes in resized one.
  /*
  //Добавление фото
  addPics(event, files) {
    console.log("Add pics files");
    this.loading = true;

    if (event.target.files && event.target.files[0]) {
      //показываем панель с превью файлов
      document.getElementById("main").classList.add('main-content-less');
      document.getElementById("main").classList.remove('main-content-full');

      //перебираем добавленные файлы
      for (let i = 0; i < event.target.files.length; i++) {
        let fileName = this.guid();
        this.getOrientation(event.target.files[i])
          .then( (orientation) => {
            console.log("Orientation: ", orientation);
            return this.resizeFile(fileName, event.target.files[i], orientation)
          })
          .then( (blob) => {
            let formData = new FormData();
            let fullName = fileName + ".jpeg";
            this.picsAdded[fileName] = "";
            formData.append('upload', blob , fullName);
            return this.projectService.fileupload(formData);
          })
          .then( (data) => {
            console.log("picsNames: " + JSON.stringify(this.picsAdded));
            if (i == (event.target.files.length-1) ) { this.loading = false;}
            }
          )
      }

    }
  }
  */


  /*
  getOrientation(data) {
    return new Promise ( (resolve, reject) =>
    {
      var reader = new FileReader();
      reader.onload = function (e: any) {

        var view = new DataView(e.target.result);
        if (view.getUint16(0, false) != 0xFFD8) resolve(-2);
        var length = view.byteLength, offset = 2;
        while (offset < length) {
          var marker = view.getUint16(offset, false);
          offset += 2;
          if (marker == 0xFFE1) {
            if (view.getUint32(offset += 2, false) != 0x45786966) reject(-1);
            var little = view.getUint16(offset += 6, false) == 0x4949;
            offset += view.getUint32(offset + 4, little);
            var tags = view.getUint16(offset, little);
            offset += 2;
            for (var i = 0; i < tags; i++)
              if (view.getUint16(offset + (i * 12), little) == 0x0112)
                resolve(view.getUint16(offset + (i * 12) + 8, little));
          }
          else if ((marker & 0xFF00) != 0xFF00) break;
          else offset += view.getUint16(offset, false);
        }
        resolve(-1);
      };
      reader.readAsArrayBuffer(data);
    });

  }


  resizeFile(filename, data, srcOrientation): Promise<Blob> {
    return new Promise((resolve, reject) => {
      //готовим файлы для превью и отрисовываем их на канвасе так же ресайзим файлы перед загрузкой
      var reader = new FileReader();
      let url: string;

      reader.onload = function (event: any) {
        url = event.target.result;

        var canvasdiv = document.getElementById("preview");

        var ddiv = document.createElement('div');
        ddiv.setAttribute('height', '50');
        ddiv.setAttribute('width', '50');
        ddiv.classList.add('loading');

        var elem = document.createElement('canvas');
        elem.id = filename;
        elem.setAttribute('height', '50');
        elem.setAttribute('width', '50');
        var context = canvasdiv.appendChild(ddiv).appendChild(elem).getContext("2d");

        var cross = document.createElement('i');
        cross.classList.add("fa");
        cross.classList.add("fa-truck");
        cross.setAttribute('color', 'red');

        document.getElementById(filename).appendChild(cross);


        var img = new Image();

        // ждать, пока изображение не будет полностью обработано
        img.onload = function () {
          //создаем превью
          switch (srcOrientation) {
            case 2: context.transform(-1, 0, 0, 1, 50, 0); break;
            case 3: context.transform(-1, 0, 0, -1, 50, 50); break;
            case 4: context.transform(1, 0, 0, -1, 0, 50); break;
            case 5: context.transform(0, 1, 1, 0, 0, 0); break;
            case 6: context.transform(0, 1, -1, 0, 50, 0); break;
            case 7: context.transform(0, -1, -1, 0, 50, 50); break;
            case 8: context.transform(0, -1, 1, 0, 0, 50); break;
            default: break;
          }

          context.drawImage(img, 0, 0, 50, 50);

          //ресайзим изображение перед загрузкой. Убрать в отдельную функцию?.
          var maxWidth = 1280,
            maxHeight = 960,
            width = img.width,
            height = img.height;

          console.log("height: ", height);
          console.log("width: ", width);

          // set proper canvas dimensions before transform & export
          if (4 < srcOrientation && srcOrientation < 9) {
            let h = height
            let w = width
            width = h;
            height = w;
          }

          if (width > height) {
            console.log("w > h");
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          }
          else {
            console.log("else");
            if (height > maxHeight) {
              console.log("h > mh");
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          console.log("height: ", height);
          console.log("width: ", width);

          let resizeElem = document.createElement('canvas');

          let ctx = resizeElem.getContext("2d");

          console.log("height: ", height);
          console.log("width: ", width);
          resizeElem.setAttribute('height', height.toString());
          resizeElem.setAttribute('width', width.toString());

          // transform context before drawing image
          // transform context before drawing image
          switch (srcOrientation) {
            case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
            case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
            case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
            case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
            case 6: ctx.transform(0, 1, -1, 0, width, 0); break;
            case 7: ctx.transform(0, -1, -1, 0, height, width); break;
            case 8: ctx.transform(0, -1, 1, 0, 0, height); break;
            default: break;
          }

          if (4 < srcOrientation && srcOrientation < 9) {
            ctx.drawImage(img, 0, 0, height, width);
          } else {
            ctx.drawImage(img, 0, 0, width, height);
          }


          let file = resizeElem.toDataURL("image/jpeg");

          var binary = atob(file.split(',')[1]);
          var array = [];
          for (var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
          }
          let blob = new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
          ddiv.classList.remove('loading');
          resolve(blob);
        };
        img.src = url;
      }
      reader.readAsDataURL(data);
    })
  }
*/

}
