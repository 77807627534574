import { Injectable } from '@angular/core';
import { Observable , BehaviorSubject} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';
import { map } from 'rxjs/operators';


interface AuthResponse {
  result: AuthResult;
}

interface AuthResult {
  access_token: string;
  refresh_token: string;
  user_id: number;
}


@Injectable()
export class AuthenticationService {

  public isadmin = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient
  ) {  }


   login(username: string, password: string) {
    return this.http.post(environment.apiPath + 'v1/auth', JSON.stringify({ username: username, password: password }))
      .pipe( map( (user: AuthResponse) => {
        console.log(user);
        // login successful if there's a jwt token in the response
        if (user && user.result.refresh_token.length > 0 ) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          const token = this.jwtDecode();
            if (token && token['role'] == 'admin') {
              this.isadmin.next(true);
            }
          return user;
        }
      }));

  }



  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
  }

  jwtDecode() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.result.access_token) {
      const token = currentUser.result.access_token;
      return token;
    }
  }

  isAdmin() {
    return this.isadmin.asObservable();
  }
}


