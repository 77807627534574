import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { PromoReport, Project } from '../../_models/user';
import {FormControl, Validators, FormGroupDirective, NgForm} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/typings/core';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-dialog-promo',
  templateUrl: './dialog-promo.component.html',
  styleUrls: ['./dialog-promo.component.css']
})
export class DialogPromoComponent implements OnInit {

  priceFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/(^0\.\d{1,2}?$)|(^([1-9](\d+)?)(\.\d{1,2})?$)/),
  ]);
  presenceControl = new FormControl('', [Validators.required]);
  extraPlaceControl = new FormControl('', [Validators.required]);
  remainsFormControl = new FormControl('', [Validators.required,Validators.pattern(/^\d{1,6}$/)]);
  priceonshelfFormControl = new FormControl('', [Validators.required]);
  pricetagselFormControl = new FormControl('', [Validators.required]);
  extendedlayoutFormControl = new FormControl('', [Validators.required]);
  dmpFormControl = new FormControl('', [Validators.required]);
  
  yesno = [
    'нет',
    'да'
  ];

  dmpArr = [
    'нет',
    '1/4 холодильника',
    '1/2 холодильника',
    'стелаж',
    'паллет',
    'баннет',
    'торец',
    'ИНОЕ'
  ];

  matcher = new MyErrorStateMatcher();

  promoRep = <PromoReport>{};


  constructor(
    public dialogRef: MatDialogRef<DialogPromoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.list.PromoReport.presence) {
      this.promoRep = data.list.PromoReport;
    }
  }

  errors(): boolean {
    var errors = false;
    if (this.priceFormControl.errors) errors = true;
    if (this.presenceControl.errors) errors = true;
    if (this.extraPlaceControl.errors) errors = true;
    if (this.data.project.Actionreportremains && this.remainsFormControl.errors) errors = true;
    if (this.data.project.Actionreportpriceonshelf && this.priceonshelfFormControl.errors) errors = true;
    if (this.data.project.Actionreportpricetagsel && this.pricetagselFormControl.errors) errors = true;
    if (this.data.project.Actionreportextendedlayout && this.extendedlayoutFormControl.errors) errors = true;
    if (this.data.project.Actionreportdmp && this.dmpFormControl.errors) errors = true;
    return errors
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {}

}
