import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class SidenavService {
  public sidepush$: EventEmitter<boolean>;

  constructor (
   private router: Router
  ) {
    this.sidepush$ = new EventEmitter();
  }

  public toggle(): void {
    this.sidepush$.emit(true);
  }

  public goto(path: string): void {
	this.router.navigate([path]);
	this.toggle();
  }

}
