import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import * as moment from 'moment';

@Component({
  selector: 'app-dialog-supply',
  templateUrl: './dialog-supply.component.html',
  styleUrls: ['./dialog-supply.component.css']
})
export class DialogSupplyComponent implements OnInit {

  supplyes = [
    { id: 0, name: 'Никогда'},
    { id: 1, name: 'Нет в матрице ТТ'},
    { id: 2, name: '1 месяц назад'},
    { id: 3, name: '3 месяца назад'},
    { id: 4, name: '6 месяцев назад'}];

  selectedValue: any;
  dateT: any;

  constructor(
    public dialogRef: MatDialogRef<DialogSupplyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    // this.dateT = moment(this.data.lastSup).format("dddd, MMMM Do YYYY, h:mm:ss a");

    if (this.data.lastSup > 0) {
      this.dateT = moment.unix(this.data.lastSup).format('MM/DD/YYYY');
    }
    if (this.data.lastSup === 0 || this.data.lastSup === -2) {
      this.dateT = 'Никогда';
    }
    if (this.data.lastSup === -1) {
      this.dateT = 'Нет в матрице ТТ';
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {}

}
