import { Component, ViewChild, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { SidenavService } from './_services/sidenav.service';
import { Shared } from './_services/nav.service';
import { AuthenticationService } from './_services/authentication.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  @ViewChild('sidenav') sidenav: MatSidenav;

  isAdmin$: Observable<boolean>;

  constructor(
      public sidenavService: SidenavService,
      private sharedResource: Shared,
      private authService: AuthenticationService,
      ) {

        sidenavService.sidepush$.subscribe(
          () => {
            this.sidenav.toggle();
          }
        );

  }

  ngOnInit () {

    this.isAdmin$ = this.authService.isAdmin();

    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (localStorage.getItem('currentUser')) {
      const token = this.authService.jwtDecode();
      console.log(token);
      if (token && token['role'] == 'admin') {
        this.authService.isadmin.next(true);
      }
    }
  }

}
