import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';


@Injectable()
export class Shared {

  public enableNavbar = new BehaviorSubject<boolean>(false);
  public navName =  new BehaviorSubject<string>('');


  get isEnableNavbar() {
    return this.enableNavbar.asObservable();
  }

  getNavbarName() {
    return this.navName.asObservable();
  }

  constructor(

  ) { }
}
