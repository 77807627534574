import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SidenavService } from '../_services/sidenav.service';
import { Shared } from '../_services/nav.service';
import { Observable} from 'rxjs';
import { Location} from '@angular/common';

@Component({
  selector: 'app-navbar-app',
  templateUrl: './navbar-app.component.html',
  styleUrls: ['./navbar-app.component.css']
})
export class NavbarAppComponent implements OnInit {

    isEnableNavbar$: Observable<boolean>;
    navbarName$: Observable<string>;

    constructor(
    public sidenavService: SidenavService,
    private  router: Router,
    private sharedResource: Shared,
    private location: Location
    ) { }

  ngOnInit() {
     this.isEnableNavbar$ = this.sharedResource.isEnableNavbar;
     this.navbarName$ = this.sharedResource.getNavbarName();
     console.log('Router: ', this.router.url);
  }

  toggle () {
    this.sidenavService.toggle();
  }

  navBack() {
    console.log(this.location);
    this.location.back();
  }

  isHome () {
    if (this.router.url === '/') {
      return true;
    }
    return false;
  }



 }
