import { Component, OnInit } from '@angular/core';
import { Shared} from '../_services/nav.service';
import { ProjectService } from '../_services/project.service';
import { RetailerService} from '../_services/retailer.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';

import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-addr',
  templateUrl: './addr.component.html',
  styleUrls: ['./addr.component.css']
})
export class AddrComponent implements OnInit {

  projectid: number;

  date: Date;

  constructor(private projectService: ProjectService,
     private sharedResource: Shared,
     private retailerService: RetailerService,
     private route: ActivatedRoute
  ) { }

  lists: Array<any>;
  projectname: string;
  public repCount = new Observable<any>();

  ngOnInit() {

    this.projectid = Number(this.route.snapshot.params['id']);

    const key = JSON.parse(localStorage.getItem('currentUser'));

    this.projectService.getProjectName(this.projectid).subscribe(
      data => {
        this.projectname = data.result;
        this.sharedResource.navName.next(this.projectname);
      },
      error => {
        console.log('Error');
      }
    );

    this.projectService.getAddr(this.projectid).subscribe(
      data => {
        console.log(data);
        this.lists = data.result;
	this.getRepCount(this.lists);
      },
      error => {
        console.log('Error');
      }
    );
  }

  passAddr (list) {
    //console.log("LIST: ", list);
    //this.sharedResource.navName.next("TEST");
  }

  getRepCount(addressList) {
    const now = moment().utc().utcOffset('+03:00').format('YYYY-MM-DD');
    for (const list of addressList) {
      this.retailerService.getAddressReportsCount(list.AddressId, this.projectid, now).
          subscribe((data) => {
              this.repCount[list.Id] = data.result;
            },
            (err) => {
              console.log(err);
            });
    }
  }

}
