import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Shared } from '../_services/nav.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor (
   private router: Router,
   private sharedResource: Shared
  ) {}

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) {
    //всегда включаем navbar. Отключаем его в тех контроллерах в которых он не нужен.
    this.sharedResource.enableNavbar.next(true);
    this.sharedResource.navName.next('');

    if (localStorage.getItem('currentUser')) {
      return true;
    }
    console.log('REDIRECT TO LOGIN');
    this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
  }
}
