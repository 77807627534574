import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormControl, Validators, NgForm, FormGroupDirective } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-dialog-entitys',
  templateUrl: './dialog-entitys.component.html',
  styleUrls: ['./dialog-entitys.component.css']
})
export class DialogEntitysComponent implements OnInit {
  qtyFormControl = new FormControl('', [Validators.required,Validators.pattern(/^\d{1,6}$/)]);
  remainsFormControl = new FormControl('', [Validators.required,Validators.pattern(/^\d{1,6}$/)]);
  virtualremainsFormControl = new FormControl('', [Validators.required,Validators.pattern(/^\d{1,6}$/)]);
  qtyexpprodFormControl = new FormControl('', [Validators.required,Validators.pattern(/^\d{1,6}$/)]);
  delayreasonFormControl = new FormControl('', [Validators.required]);
  priceonshelfFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/(^0\.\d{1,2}?$)|(^([1-9](\d+)?)(\.\d{1,2})?$)/),
  ]);
  qtyexpdateFormControl = new FormControl('', [Validators.required,Validators.pattern(/^\d{1,6}$/)]);

  matcher = new MyErrorStateMatcher();

  delReas = [
    '---',
    'большой товарный запас',
    'плохие продажи',
    'ИНОЕ'
  ];

  constructor(
    public dialogRef: MatDialogRef<DialogEntitysComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  errors(): boolean {
    var errors = false;
    //if (this.qtyFormControl.errors) errors = true;
    //if (this.data.Reportremains && this.remainsFormControl.errors) errors = true;

    

    if (this.data.Reportremains && this.qtyFormControl.errors) errors = true;
    if (this.data.Reportvirtualremains && this.virtualremainsFormControl.errors) errors = true;
    if (this.data.Reportqtyexpprod && this.qtyexpprodFormControl.errors)  errors = true;
    if (this.data.Reportdelayreason && this.delayreasonFormControl.errors) errors = true;
    if (this.data.Reportpriceonshelf && this.priceonshelfFormControl.errors) errors = true;
    if (this.data.Reportqtyexpdate && this.qtyexpdateFormControl.errors) errors = true;
    return errors
  }

  onNoClick(): void {
    console.log('Close');
    this.dialogRef.close();
  }

  ngOnInit() {
  }

}


